import React, { useEffect, useState } from "react";
import "./App.css";
import { Optimism, useEthers } from "@usedapp/core";
import FundsRequest from "./FundsRequest";
import ConnectScreen from "./ConnectScreen";

function App() {
  const { account, isLoading, library, switchNetwork } = useEthers();
  const [switching, setSwitching] = useState(false);

  useEffect(() => {
    if (account && library?.network.chainId !== Optimism.chainId && !switching) {
      setSwitching(true);
      switchNetwork(Optimism.chainId)
        .then(() => setSwitching(false))
        .catch(() => setSwitching(false));
    }
  }, [account, switchNetwork, switching, library]);

  return (
    <div className="App">
      {!isLoading && (account ? <FundsRequest /> : <ConnectScreen />)}
    </div>
  );
}

export default App;
