import { EXPLORER_BASE_URL } from "../constants";

export default function Transaction({ txHash }: { txHash: string }) {
  return (
    <a
      href={`${EXPLORER_BASE_URL}/tx/${txHash}`}
      target="_blank"
      rel="noreferrer"
      className="address"
    >
      {txHash}
    </a>
  );
}
