import { EXPLORER_BASE_URL } from "../constants";

export default function Address({ account }: { account: string }) {
  return (
    <a
      href={`${EXPLORER_BASE_URL}/address/${account}`}
      target="_blank"
      rel="noreferrer"
      className="address"
    >
      {account}
    </a>
  );
}
